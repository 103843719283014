<template>
  <v-dialog
    v-model="show"
    max-width="300"
  >
    <v-card>
      <v-card-title class="headline">{{title}}</v-card-title>

      <v-card-text>{{text}}</v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="show = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true,
      },
      title: String,
      text: String,
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          return this.$emit('input', value)
        }
      }
    }
  }
</script>